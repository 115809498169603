.support_container {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}
.support_detail {
  margin: 20px;
  padding: 40px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.15);
  .title {
    color: #071135;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0px 0px 16px 0px;
  }
  .titleValue {
    margin-left: 10px;
    font-size: 16px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 300;
  }
}
.support_card {
  // margin-bottom: 24px;
  .heading {
    color: #272b39;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 12px;
  }
  .item_value {
    color: #272b39;
    font-family: Arial;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
  }
  .reply_btn .ant-btn {
    width: 150px !important;
    height: 60px !important;
    margin: 10px 20px 10px 20px !important;
  }
}
.supportModal {
  display: flex;
  justify-content: center;
  align-items: center;
  .buttonCol {
    display: flex;
    justify-content: center;
    align-items: center;
    .reply_btn {
      width: 150px !important;
      height: 60px !important;
      margin: 10px 20px 10px 20px !important;
      border-radius: 2px !important;
    }
  }
}
.supportCardContainer .ant-card-body {
  padding: 0px;
}
.supportCards {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: calc(100vh - 279px);
  overflow-y: auto;
}
