.candidate_detail_box {
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.15);
  padding: 18px;
  color: #272b39;
  font-family: Arial;
  .text {
    font-size: 16px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
  .name {
    color: #272b39;
    font-family: Arial;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    margin-top: 16px;
  }
  .address {
    max-width: 500px;
  }
  .ant-form-item {
    margin-bottom: 44px !important;
  }
  label {
    color: #272b39 !important;
  }
  .ant-form-item-row {
    flex-direction: column !important;
    .ant-form-item-label {
      text-align: left !important;
    }
    .ant-form-item-label > label {
      font-family: Arial;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #333333 !important;
    }
    .ant-form-item-label > label::after {
      content: "" !important;
    }
    .ant-form-item-label > label::before {
      content: "" !important;
    }
  }
  .ant-upload-select {
    width: 100% !important;
  }
  .ant-upload-list-item {
    display: none !important;
  }
}

.resumeModal{
  .ant-modal-content{
    width: 700px;
  }
  .containner{
    margin-top: 10px;
  }
  .editResume{
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
  }
}
.item {
  color: #071135 !important;
  font-family: Arial;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400;
  margin: 0 !important;
  line-height: normal;
}
.value {
  color: #071135 !important;
  font-family: Arial;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 700 !important;
  line-height: normal;
  margin-top: 10px;
  margin-bottom: 0px;
  // text-transform: capitalize;
}