.detail_container {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-text-fill-color: rgba(0, 0, 0, 0.88) !important;
    -webkit-box-shadow: 0 0 0 30px #fff inset !important;
  }
  background-color: #fff;
  margin-top: 20px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.15);
  color: #272b39;
  font-family: Arial, Helvetica, sans-serif;

  .buttonCol {
    display: flex;
    justify-content: flex-end;
    .btn {
      background-color: black;
      color: #fff;
      width: 100%;
      &:hover {
        background-color: black;
        color: #fff !important;
      }
    }
  }
  .meta_col1 {
    margin-bottom: -7px;
  }
  .meta_col {
    display: flex;
    justify-content: center;
  }
  .previewTitle {
    font-family: Arial;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 20px;
  }
  .standard_plan_card_container::-webkit-scrollbar{
    display: none;
  }
  .standard_plan_card_container {
    width: 294px;
    height: 412px;
    padding: 20px;
    gap: 40px;
    border-radius: 20px;
    border: 1px solid var(--greys-grey-300, #e4e8ed);
    background: var(--greys-primary-white, #e4e8ed);
    overflow-y: auto;
    box-shadow: 1px 2px 12px 0px rgba(0, 0, 0, 0.08);

    .content_row {
      margin-top: 40px;
      .priceSpan {
        color: var(--Primary-Black, #242424);
        font-family: Arial;
        font-size: 37px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
      .recurrType {
        color: #151514;
        font-family: Arial;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
    .bulletPoint {
      align-items: end;
    color: #151514;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    word-wrap: break-word;
  }
    .none {
      display: none;
    }
    .dpImage {
      border: 1px solid #fff;
      border-radius: 50%;
    }
    .title {
      margin-bottom: 1px;
      color: #161615;
      font-family: Arial;
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    .active {
      display: flex;
      padding: 0px 8px;
      align-items: center;
      text-align: center !important;
      border-radius: 12px;
      border: 1px solid #93da9f;
      background: #caf3d1;
      color: var(--blues-blue-800, #091647);
      font-family: Arial;
      font-size: 16px !important;
      font-style: normal;
      font-weight: 400 !important;
      line-height: normal;
    }

    .dp_image {
      border-radius: 50%;
      width: 20px;
    }

    .gap-16 {
      gap: 16px;
      display: flex;
    }

    h4 {
      margin: 0px;
      color: var(--blues-blue-900, #071135);
      font-family: Arial;
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px;
      display: inline;
    }

    p {
      display: inline;
      color: var(--blues-blue-900, #071135);
      font-family: Arial;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin: 0px;
    }

    .price_holder {
      margin-left: 60px;
    }

    .benefits_holder {
      //   margin-top: 28px;
      .gap-10 {
        align-items: center;
        display: flex;
        gap: 10px;
      }
      .mt-24 {
        margin-top: 24px;
      }
      .mt-12 {
        margin-top: 12px;
      }
      .mt-10 {
        margin-top: 10px;
      }
      .mt-16 {
        margin-top: 16px;
      }

      .mb-80 {
        margin-bottom: 80px;
      }
      .mb-40 {
        margin-bottom: 40px;
      }

      p {
        color: var(--blues-blue-900, #071135);
        font-family: Arial;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 0px;
      }
    }
  }
}

.phoneIcon {
  width: 35px;
  height: 35px;
  padding: 4px;
  border: 1px solid #e3e3e3;
  border-radius: 5px;
}
.renderDiv {
  display: flex;
  flex-wrap: wrap;
}
.renderSpan {
  border: 1px solid gray;
  border-radius: 2px;
  padding: 3px 10px 3px 10px;
  margin: 5px;
}
.crossIcon {
  font-size: 10px;
  cursor: pointer;
}
.renderTitle {
  margin-right: 5px;
  font-family: Arial, Helvetica, sans-serif;
}

.ant-table-filter-dropdown .ant-btn-link {
  // display: none; /* Hide the reset button */
  visibility: hidden;
}