
.create_resource{
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      -webkit-text-fill-color: rgba(0, 0, 0, 0.88) !important;
      -webkit-box-shadow: 0 0 0 30px #fff inset !important;
    }
    background-color: #fff;
    margin-top: 30px;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.15);
    color: #272b39;
    font-family: Arial;
    .inputCol{
        .input-phn{
            box-sizing: border-box !important;
            margin: 0 !important;
            padding: 8px 11px !important;
            color: rgba(0, 0, 0, 0.88);
            font-size: 14px;
            line-height: 1.5714285714285714;
            list-style: none;
            font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
            position: relative;
            display: flex;
            width: 100%;
            min-width: 0;
            background-color: #ffffff;
            background-image: none;
            border-width: 1px;
            border-style: solid;
            border-color: #d9d9d9;
            border-radius: 6px;
            border-end-end-radius: 0px !important;
            border-start-end-radius: 0px !important;
            transition: all 0.2s;
            & input{
                display: none;
                }
        }
        .input-phn-no{
            border-start-start-radius: 0px;
            border-end-start-radius: 0px;
            width: 100%;
        } 
    }
    .detail_col{
        padding: 10px;
        margin-bottom: 10px;
    }
    .edit_col{
        padding: 10px;
        margin-bottom: 10px;
        display: flex;
        justify-content: flex-end;
        .edit_btn{
            font-size: 18px;
        }
    }
    .title{
        font-family: Arial, Helvetica, sans-serif;
        font-size: 18px;
        font-weight: 700;
    }
    .image{
        width: 100px;
        height: 100px;
        border-radius: 10px;
    }
    .title_value{
        font-family: Arial, Helvetica, sans-serif;
        font-size: 16px;
        font-weight: 400;
    }
    .buttonCol{
        display: flex;
        justify-content: flex-end;
        .btn{
            background-color: black;
            color: #fff;
            width: 100%;
            &:hover{
                    background-color: black !important;
                    color: #fff !important;
                }
        }
    }
}