
.picklist_container{
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      -webkit-text-fill-color: rgba(0, 0, 0, 0.88) !important;
      -webkit-box-shadow: 0 0 0 30px #fff inset !important;
    }
    margin-top: 20px;
    display: flex;
    justify-content: center;
    padding: 15px;
    background-color: #fff;
    border-radius: 10px;
    .heading{
        margin: 10px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 30px;
        font-weight: 600;
    }
    .inner_heading{
        padding: 10px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 20px;
        font-weight: 600; 
    }
    .action_container{
        padding-left: 20px;
        padding-top: 10px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 15px;
        font-weight: 500; 
    }
    .table_container{
        display: flex;
        justify-content: center;
    }
}
.buttonCol{
    display: flex;
    justify-content: flex-end;
    .btn{
        background-color: black;
        color: #fff;
        width: 100%;
        &:hover{
                background-color: black !important;
                color: #fff !important;
            }
    }
}
.filter_Select .ant-select-selector{    
    height: 43px !important;
    align-items: center !important;
  }
