.manualNotificationMainDiv {
  margin-top: 20px;
  .noti_section {
    .noti_textArea {
      margin-top: 10px;
    }
  }
  .radio_btn_sec {
    // margin-top: 10px;
  }
  .table_section {
    // margin-top: 50px;
  }
}
