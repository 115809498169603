.applied_jobs_container {
  .heading {
    color: #272b39;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 12px;
  }
  .item_value {
    color: #272b39;
    font-family: Arial;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
  }
}
