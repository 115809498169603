.detail_container{
    background-color: #fff;
    margin-top: 10px;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.15);
    color: #272b39;
    font-family: Arial, Helvetica, sans-serif;
    .detail_row{
        padding: 20px;
        .detail_title{
            font-size: 15px;
            font-weight: 700;
            padding: 3px;
        }
        .detail_title_value{
            font-weight: 400;
            padding: 3px;
        }
    }
    .row_container{
        margin: 10px;
        padding: auto;
    }
   .heading{
    color: #272b39;
    font-family: Arial;
    font-size: 25px;
    font-style: normal;
    font-weight: 800;
    line-height: 12px;
    margin-bottom: 20px;
   }
}
.tab-btn-holder {
    display: flex;
    justify-content: flex-end;
    margin: 0px 20px 10px 0px;
    .active {
      background-color: rgba(22, 56, 177, 1);
      padding: 9px;
    }
    .inactive {
      padding: 9px;
      background-color: rgba(200, 210, 248, 1);
    }
  }