.detail_container {
  .title {
    font-size: 18px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 600;
  }
  .titleValue {
    font-size: 16px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 300;
  }
  .editIcon {
    font-size: 20px;
  }
  .textBox {
    min-height: 200px;
  }
  .reactQuillBox {
    height: calc(100vh - 380px);
    max-height: calc(100vh - 380px);
    // overflow-y: auto;
  }
  .previewImage {
    width: 100%;
  }
  .imgCol {
    margin: 5px;
    position: relative;
    height: 120px;
    width: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #cfcfcf;
    border-radius: 5px;
  }
  .image {
    width: 120px;
    object-fit: contain;
    height: 120px;
  }
  .editImage {
    margin-left: 10px;
    // position: absolute;
    // top: 10px;
    // right: 10px;
  }
}
.newsListImage {
  width: 116px !important;
  height: 60px !important;
  border-radius: 5px !important;
}
.newsDetail {
  padding: 20px;
  .container {
    margin-bottom: 10px;
  }
  .imageContainer {
    margin-bottom: 10px;
    img {
      width: 120px;
      object-fit: contain;
      height: 120px;
    }
    margin-bottom: 30px;
  }
}
