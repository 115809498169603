.containner_row{
    display: flex;
    justify-content: center;
    .containner {
        padding: 30px;
        background-color: #fff;
        border-radius: 10px;
        box-shadow:  0px 1px 4px 0px rgba(0, 0, 0, 0.15);
    }
}
.buttonCol{
    display: flex;
    justify-content: flex-end;
    .btn1{
        background-color: black;
        color: #fff;
        &:hover{
                background-color: black !important;
                color: #fff !important;
            }
    }
}