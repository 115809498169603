.previewContainer {
  background-color: #fff;
  margin-top: 20px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.15);
  color: #272b39;
  font-family: Arial, Helvetica, sans-serif;
  display: flex;
  justify-content: center;

  .preview{
    font-family: Arial;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 20px;
  }
  .innerContainer {
    margin-top: 20px;
    border: 7px solid #fff;
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.85) inset;
    width: 340px !important;
    transform-origin: 1115px 111.5px;
    border: 5px solid #080608;
    outline: 5px solid #080608;
    outline: 5px solid #babab6;
    border-radius: 40px;
    border-radius: 40px !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-bottom: 0px !important;
    background: var(--states-caution-yellow, #fcc009);
    z-index: 10;

    .phone_modal_main_content_row {
      height: 663.016px;
      overflow: hidden !important;
      border-radius: 40px !important;
      .yellow_icon {
        height: 42px !important;
        width: 42px !important;
        position: absolute;
        top: 3%;
        right: 18%;
      }
      .yellow_icon_half {
        position: absolute;
        top: 11%;
        right: 0;
      }
      .titleMainRow {
        margin-top: 50px;
        padding: 0 25px;
        span {
          color: var(--Primary-Black, #242424);
          text-align: center;
          font-size: 32px;
          font-family: microftFont;
          font-style: normal;
          font-weight: 400;
          line-height: 30px; /* 71.429% */
        }
      }

      .titleColumn {
        margin-top: 20px;
        width: 90%;
        display: inline-flex;
        padding: 16px;
        flex-direction: column;
        justify-content: center;
        gap: 16px;
        border-radius: 15px;
        background: #fff;
        position: absolute;
        z-index: 100000;
        left: 5%;
        box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.08);
        .wallet {
          padding: 7px;
          border: 2px solid #ededed;
          border-radius: 50%;
        }
        .walletIcon {
          width: 28px;
        }
      }
      .title {
        margin-bottom: 6px;
        color: #161615;
        font-family: Arial;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
      .titleDesc {
        color: var(--Secondary-Grey, #909090);
        /* Body Text/Untitled/B1 */
        font-family: Arial;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      .content_main_div {
        margin-top: 50px !important;
        position: relative;
        min-height: 700px !important;
        border-radius: 50px 50px 0px 0px;
        background: var(--BG, #f9fafd);
        z-index: 10000;
        padding: 25px;
        overflow-y: auto;
        .content_row {
          margin-top: 40px;
          .priceSpan {
            color: var(--Primary-Black, #242424);
            font-family: Arial;
            font-size: 37px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }
          .recurrType {
            color: #151514;
            font-family: Arial;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
        .bulletPoint {
            align-items: end;
          color: #151514;
          font-family: Arial;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          word-wrap: break-word;
        }
        .location_row {
          margin-top: 20px;
          .location_text {
            overflow: hidden;
            color: var(--Primary-Black, #242424);
            text-overflow: ellipsis;
            font-family: Arial;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%; /* 14px */
          }
        }
        .status_main_div {
          border-radius: 15px;
          margin-top: 20px;
          background: var(--Tab-white, #fff);
          box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
          p {
            margin: 0 !important;
          }
          .status_inner_div {
            padding: 20px !important;
            .title_heading {
              color: var(--Tertiary-Grey, #b9b9b9);
              font-family: Arial;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
            .title_desc {
              color: var(--Primary-Black, #242424);
              font-family: Arial;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 140%; /* 19.6px */
              letter-spacing: 0.035px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
        }
        .job_description_main_row {
          .job_Desc_title {
            color: var(--Primary-Black, #242424);
            font-family: Arial;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 200%;
          }
          .job_Desc_content {
            color: var(--Primary-Black, #242424);
            font-family: Arial;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%; /* 19.6px */
            letter-spacing: 0.035px;
            max-height: 100px !important;
            overflow-y: scroll !important;
          }
          .job_Desc_content::-webkit-scrollbar {
            display: none;
          }
        }
      }
    }
  }
}

.PhoneViewModalMainContainer1 {
  display: flex;
  justify-content: center;

  .ant-modal-content {
    border-radius: 40px !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-bottom: 0px !important;
    box-shadow: none;
    background: none;
    z-index: 10;
  }
  .preview{
    font-family: Arial;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 20px;
  }
  .innerContainer {
    border: 7px solid #fff;
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.85) inset;
    width: 340px !important;
    transform-origin: 1115px 111.5px;
    border: 5px solid #080608;
    outline: 5px solid #080608;
    outline: 5px solid #babab6;
    border-radius: 40px;
    border-radius: 40px !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-bottom: 0px !important;
    background: var(--states-caution-yellow, #fcc009);
    z-index: 10;

    .phone_modal_main_content_row {
      height: 663.016px;
      overflow: hidden !important;
      border-radius: 40px !important;
      .yellow_icon {
        height: 42px !important;
        width: 42px !important;
        position: absolute;
        top: 3%;
        right: 18%;
      }
      .yellow_icon_half {
        position: absolute;
        top: 11%;
        right: 0;
      }
      .titleMainRow {
        margin-top: 50px;
        padding: 0 25px;
        span {
          color: var(--Primary-Black, #242424);
          text-align: center;
          font-size: 32px;
          font-family: microftFont;
          font-style: normal;
          font-weight: 400;
          line-height: 30px; /* 71.429% */
        }
      }

      .titleColumn {
        margin-top: 20px;
        width: 90%;
        display: inline-flex;
        padding: 16px;
        flex-direction: column;
        justify-content: center;
        gap: 16px;
        border-radius: 15px;
        background: #fff;
        position: absolute;
        z-index: 100000;
        left: 5%;
        box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.08);
        .wallet {
          padding: 7px;
          border: 2px solid #ededed;
          border-radius: 50%;
        }
        .walletIcon {
          width: 28px;
        }
      }
      .title {
        margin-bottom: 1px;
        color: #161615;
        font-family: Arial;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
      .titleDesc {
        color: var(--Secondary-Grey, #909090);
        /* Body Text/Untitled/B1 */
        font-family: Arial;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      .content_main_div {
        margin-top: 50px !important;
        position: relative;
        min-height: 700px !important;
        border-radius: 50px 50px 0px 0px;
        background: var(--BG, #f9fafd);
        z-index: 10000;
        padding: 25px;
        overflow-y: auto;
        .content_row {
          margin-top: 40px;
          .priceSpan {
            color: var(--Primary-Black, #242424);
            font-family: Arial;
            font-size: 37px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }
          .recurrType {
            color: #151514;
            font-family: Arial;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
        .bulletPoint {
            align-items: end;
          color: #151514;
          font-family: Arial;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          word-wrap: break-word;
        }
        .location_row {
          margin-top: 20px;
          .location_text {
            overflow: hidden;
            color: var(--Primary-Black, #242424);
            text-overflow: ellipsis;
            font-family: Arial;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%; /* 14px */
          }
        }
        .status_main_div {
          border-radius: 15px;
          margin-top: 20px;
          background: var(--Tab-white, #fff);
          box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
          p {
            margin: 0 !important;
          }
          .status_inner_div {
            padding: 20px !important;
            .title_heading {
              color: var(--Tertiary-Grey, #b9b9b9);
              font-family: Arial;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
            .title_desc {
              color: var(--Primary-Black, #242424);
              font-family: Arial;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 140%; /* 19.6px */
              letter-spacing: 0.035px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
        }
        .job_description_main_row {
          .job_Desc_title {
            color: var(--Primary-Black, #242424);
            font-family: Arial;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 200%;
          }
          .job_Desc_content {
            color: var(--Primary-Black, #242424);
            font-family: Arial;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%; /* 19.6px */
            letter-spacing: 0.035px;
            max-height: 100px !important;
            overflow-y: scroll !important;
          }
          .job_Desc_content::-webkit-scrollbar {
            display: none;
          }
        }
      }
    }
  }
 
}
.PhoneViewModalClient1{
  display: flex;
  justify-content: center;
  .ant-modal-content {
    border-radius: 40px !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-bottom: 0px !important;
    box-shadow: none;
    background: none;
    width: 340px;
    left: 100px;
    z-index: 10;
  }
  .standard_plan_card_container::-webkit-scrollbar{
    display: none;
  }

  .standard_plan_card_container {
    width: 294px;
    height: 412px;
    padding: 20px;
    gap: 40px;
    border-radius: 20px;
    border: 1px solid var(--greys-grey-300, #e4e8ed);
    background: var(--greys-primary-white, #e4e8ed);
    box-shadow: 1px 2px 12px 0px rgba(0, 0, 0, 0.08);
    overflow-y: auto;
  
    .content_row {
      margin-top: 40px;
      .priceSpan {
        color: var(--Primary-Black, #242424);
        font-family: Arial;
        font-size: 37px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
      .recurrType {
        color: #151514;
        font-family: Arial;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
    .bulletPoint {
      align-items: end;
    color: #151514;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    word-wrap: break-word;
  }
    .none {
      display: none;
    }
    .dpImage {
      border: 1px solid #fff;
      border-radius: 50%;
    }
    .title {
      margin-bottom: 1px;
      color: #161615;
      font-family: Arial;
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    .active {
      display: flex;
      padding: 0px 8px;
      align-items: center;
      text-align: center !important;
      border-radius: 12px;
      border: 1px solid #93da9f;
      background: #caf3d1;
      color: var(--blues-blue-800, #091647);
      font-family: Arial;
      font-size: 16px !important;
      font-style: normal;
      font-weight: 400 !important;
      line-height: normal;
    }
  
    .dp_image {
      border-radius: 50%;
      width: 20px;
    }
  
    .gap-16 {
      gap: 16px;
      display: flex;
    }
  
    h4 {
      margin: 0px;
      color: var(--blues-blue-900, #071135);
      font-family: Arial;
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px;
      display: inline;
    }
  
    p {
      display: inline;
      color: var(--blues-blue-900, #071135);
      font-family: Arial;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin: 0px;
    }
  
    .price_holder {
      margin-left: 60px;
    }
  
    .benefits_holder {
      //   margin-top: 28px;
      .gap-10 {
        align-items: center;
        display: flex;
        gap: 10px;
      }
      .mt-24 {
        margin-top: 24px;
      }
      .mt-12 {
        margin-top: 12px;
      }
      .mt-10 {
        margin-top: 10px;
      }
      .mt-16 {
        margin-top: 16px;
      }
  
      .mb-80 {
        margin-bottom: 80px;
      }
      .mb-40 {
        margin-bottom: 40px;
      }
  
      p {
        color: var(--blues-blue-900, #071135);
        font-family: Arial;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 0px;
      }
    }
  }
}
