.search_container {
  display: flex;
  justify-content: flex-end;
  .btn_Col {
    display: flex;
    justify-content: flex-end;
  }
  .ant-input-affix-wrapper {
    height: 43px !important;
  }
  .ant-input-wrapper {
    height: 43px !important;
    input {
      // height: 43px !important;
      outline: none !important;
    }
    .ant-input {
      border-color: #bcc3d0 !important;
      outline: none !important;
    }
    .ant-btn-default {
      border-color: #bcc3d0 !important;
    }

    button {
      height: 43px !important;
    }
  }
  .btn {
    background-color: #071135;
    color: #fff;
    height: 45px;
    &:hover {
      color: #fff !important;
      height: 45px;
    }
  }
}
.table_scroll {
  width: 100%;
  margin-top: 30px;
  height: calc(100vh - 318px);
  max-height: calc(100vh - 268px);
  overflow-y: auto;
}
.table_container {
  margin-top: 10px;
  ::-webkit-scrollbar {
    width: 0px !important; /* width of the entire scrollbar */
    height: 0px !important;
  }
}
