.layout_main_component {
  background: #f2f4f8 !important;
  font-family: Arial !important;
  .header {
    background: #071135;
  }
  .user-decs {
    padding-right: 24px;
  }
  .avatar-container {
    margin-right: 10px;
    .ant-avatar {
      background: #e3e9fc !important;
    }
    .profileAvatarName {
      color: #071135 !important;
      // text-align: center;
      font-family: Arial;
      font-size: 18px !important;
      font-style: normal;
      font-weight: 400;
      line-height: normal !important;
      display: flex !important;
      align-items: center !important;
      height: 100% !important;
      justify-content: center !important;
      width: 100% !important;
      text-transform: capitalize !important;
    }
  }
  .pageTitleBox {
    border-radius: 0px 0px 16px 16px;

    border-radius: 0px 0px 16px 16px;
    padding: 24px;
  }
  .box {
    margin: 10px 0px 10px 0px;
  }
  .boxWhite {
    background-color: #fff !important;
    padding: 10px 0px 20px 0px;
  }
  .childrenBox {
    padding: 0px 24px 0px 24px;
  }
  .childrenBoxWhite {
    background-color: #fff !important;
    padding: 0px 24px 0px 24px;
  }
  .backIcon {
    margin: 20px 0px 0px 20px;
    font-size: 20px;
  }
  .pageTitleBox {
    font-family: Arial;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: #272b39;
    margin: 0 !important;
  }
  .mainSidebar {
    height: calc(100vh - 100px);
    overflow-y: auto;
    background: #071135 !important;
    padding: 12px !important;
  }
  .contentMainContainer {
    width: calc(100vw - 270px);
  }
  .mainContainer {
    max-height: calc(100vh - 100px);
    overflow-y: auto;
    overflow-x: hidden;
  }
  .profileName {
    color: #fff;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 175%;
    margin: 0 !important;
  }
  .designation {
    color: #9d9d9d;
    font-family: Arial;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    margin: 0 !important;
  }
  .pageHeading {
    color: #04040b;
    font-family: Arial;
    font-size: 33px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
  }
  .ant-menu-light {
    background: transparent !important;
  }
  .menu_link {
    color: #fffcfc !important;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    margin: 0 !important;
    line-height: 20px;
  }
  .ant-menu-item-selected {
    background: #f9cf66 !important;
    // width: 100% !important;
    // margin: 0px !important;

    border-radius: 0px !important;
    .menu_link {
      color: #272b39 !important;
    }
  }

  .ant-menu-item {
    border-radius: 0px !important;
    // margin: 0px !important;
    // width: 100% !important;
    border-radius: 12px !important;
  }
  .ant-menu-submenu-arrow::after {
    background-color: #fffcfc !important;
  }
  .ant-menu-submenu-arrow::before {
    background-color: #fffcfc !important;
  }
  .logoutBtn {
    border-radius: 5px !important;
    background: #f9cf66 !important;
    text-align: center;
    font-family: Arial;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    padding: 8px 16px !important;
    height: auto !important;
    color: #fff !important;
  }
}
.actionDropdown {
  .item {
    display: flex;
    display: flex;
    width: 131px;
    padding: 10px;
    align-items: center;
    gap: 6px;
    // border-radius: 8px 8px 0px 0px;
    border-bottom: 0.5px solid var(--greys-grey-300, #e4e8ed);
    background: var(--greys-primary-white, #fff);
    color: #071135 !important;
    cursor: pointer;
    font-family: Arial;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .logOutItem {
    color: #d03c28 !important;
    border-bottom: none !important;
  }
  .item:hover {
    background-color: #e3e9fc !important;
    // border-radius: 4px !important;
  }

  .ant-dropdown-menu-item {
    background-color: white !important;
    padding: 0 !important;
  }
}
