.PhoneViewModalMainContainer {
    border: 7px solid #fff;
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.85) inset;
    width: 390px !important;
    transform-origin: 1115px 111.5px;
    border: 5px solid #080608;
    outline: 5px solid #080608;
    outline: 5px solid #babab6;
    border-radius: 40px;
  
    .ant-modal-content {
      border-radius: 40px !important;
      padding-left: 0 !important;
      padding-right: 0 !important;
      padding-bottom: 0px !important;
      background: var(--states-caution-yellow, #fcc009);
      z-index: 10;
    }
    .phone_modal_main_content_row {
      height: 713.016px;
      overflow: hidden !important;
      border-radius: 40px !important;
      .yellow_icon {
        height: 42px !important;
        width: 42px !important;
        position: absolute;
        top: 2%;
        right: 30%;
      }
      .yellow_icon_half {
        position: absolute;
        top: 9%;
        right: 0;
      }
      .titleMainRow {
        margin-top: 30px;
        padding: 0 25px;
        span {
          color: var(--Primary-Black, #242424);
          text-align: center;
          font-size: 35px;
          font-family: microftFont;
          font-style: normal;
          font-weight: 400;
          line-height: 30px; /* 71.429% */
        }
      }
      .content_main_div {
        margin-top: 40px !important;
        position: relative;
        min-height: 700px !important;
        border-radius: 50px 50px 0px 0px;
        background: var(--BG, #f9fafd);
        z-index: 10000;
        padding: 25px;
        overflow-y: scroll !important;
        .content_row {
          .Advert_title {
            color: var(--Primary-Black, #242424);
            font-family: Arial;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            margin: 0 !important;
            line-height: 30px; /* 150% */
          }
          .Advert_Desc {
            margin: 0 !important;
            color: var(--Primary-Grey, #575756);
  
            /* Body Text/B1 */
            font-family: Arial;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
        .location_row {
          margin-top: 20px;
          .location_text {
            overflow: hidden;
            color: var(--Primary-Black, #242424);
            text-overflow: ellipsis;
            whitespace: nowrap;
            font-family: Arial;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%; /* 14px */
          }
        }
        .status_main_div {
          border-radius: 15px;
          margin-top: 20px;
          background: var(--Tab-white, #fff);
          box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
          p {
            margin: 0 !important;
          }
          .status_inner_div {
            padding: 20px !important;
            .title_heading {
              color: var(--Tertiary-Grey, #b9b9b9);
              font-family: Arial;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
            .title_desc {
              color: var(--Primary-Black, #242424);
              font-family: Arial;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 140%; /* 19.6px */
              letter-spacing: 0.035px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
        }
        .job_description_main_row {
          .job_Desc_title {
            color: var(--Primary-Black, #242424);
            font-family: Arial;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 200%;
          }
          .job_Desc_content {
            color: var(--Primary-Black, #242424);
            font-family: Arial;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%; /* 19.6px */
            letter-spacing: 0.035px;
            max-height: 100px !important;
            overflow-y: scroll !important;
          }
          .job_Desc_content::-webkit-scrollbar {
              display: none;
            }
        }
      }
    }
  }
  