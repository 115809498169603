.applicant_profile_main_container {
  .personal_detail_container {
    height: calc(100vh - 210px);
    // overflow-y: hi;
    overflow: hidden;
    border-radius: 12px;
    border: 1px solid #e4e8ed;
    background: #fff;
    .personal_detail_top {
      padding: 24px 30px;
    }
    .personal_detail_scroll {
      margin-top: 32px;
      overflow-x: hidden;
      height: calc(100vh - 530px);
      overflow-y: auto;
      // padding: 24px 0px 24px 30px;
      margin: 0px 10px 0px 30px;
      padding: 0px 10px 0px 0px;
    }
    .tagBox {
      border-radius: 15px;
      border: 1.5px solid #f9cf66;
      background: #fae7b6;
      color: #071135;
      text-align: center;
      font-family: Arial;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 34px;
      cursor: pointer;
    }
    .name {
      color: #071135;
      width: 100%;
      text-align: center;
      font-family: Arial;
      font-size: 36px !important;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-top: 30px;
      margin-bottom: 5px;
      @media screen and (max-width: "1919px") {
        font-size: 24px !important;
      }
    }
    .citizen{
      color: #071135;
      width: 100%;
      text-align: center;
      font-family: Arial;
      font-size: 10px !important;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-bottom: 5px;
      @media screen and (max-width: "1919px") {
        font-size: 15px !important;
      }
    }
    .detail_box {
      margin-top: 32px;
      .item {
        color: #071135 !important;
        font-family: Arial;
        font-size: 14px !important;
        font-style: normal;
        font-weight: 400;
        margin: 0 !important;
        line-height: normal;
      }
      .value {
        color: #071135 !important;
        font-family: Arial;
        font-size: 16px !important;
        font-style: normal;
        font-weight: 700 !important;
        line-height: normal;
        margin-top: 10px;
        margin-bottom: 0px;
        // text-transform: capitalize;
      }
    }
    .firstBox {
      margin-top: 0px !important;
    }
  }
  .professional_detail_container {
    height: calc(100vh - 210px);
    overflow-y: auto;
    padding: 0px 10px 0px 0px !important;
    overflow-x: hidden;
    .mt-40 {
      margin-top: 40px;
    }

    .professional_detail_content {
      margin-top: 24px;
      .item {
        color: #071135 !important;
        font-family: Arial;
        font-size: 14px !important;
        font-style: normal;
        font-weight: 400;
        margin: 0 !important;
        line-height: normal;
      }
      .value {
        color: #071135 !important;
        font-family: Arial;
        font-size: 16px !important;
        font-style: normal;
        font-weight: 700 !important;
        line-height: normal;
        margin-top: 10px;
        margin-bottom: 0px;
        word-break: break-all;
      }
      .horizontalDivider {
        margin: 20px 0px !important;
      }
    }
  }
}
.title {
  color: #071135;
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0px 0px 16px 0px;
}
.other_detail_container {
  .titleBox {
    border-radius: 15px 15px 0px 0px;
    border-bottom: 1px solid #e4e8ed;
    background: #f8f9ff;
    color: #071135;
    padding: 20px;
    font-family: Arial;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 12px;
  }
  .detail-container {
    background-color: #fff;
    border-radius: 8px;
    border-radius:  0px 0px 15px 15px;
    padding: 20px;
  }
  .citizen_container {
    border-radius: 15px;
    // border: 1px solid #e4e8ed;
  }
  .additional_detail_container {
    border-radius: 15px;
    border: 1px solid #e4e8ed;
    margin-top: 20px;
  }
  .clearance_box {
    border-radius: 15px;
    border: 1px solid #e4e8ed;
    background: #fff;
    padding: 20px;
    margin-top: 20px;
  }
}
.jobPositionCard {
  margin-top: 20px;
  border-radius: 15px;
  border: 1px solid #e4e8ed;
  background: #fff;
  padding: 20px;
  .horizontalDivider {
    margin: 20px 0px !important;
  }
  .title {
    color: #071135;
    font-family: Arial;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 12px;
    margin: 0 !important;
  }
  .itemRow {
    margin-bottom: 18px !important;
  }
  .currentRole {
    border-radius: 100px;
    background: #bff0be;
    display: flex;
    padding: 6px 8px;
    justify-content: center;
    align-items: center;
    color: #236b21;
    font-family: Arial;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0 !important;
  }
  .item {
    color: #071135;
    font-family: Arial;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0 !important;
    span {
      font-weight: 700;
      font-size: 16px;
    }
  }
  .value {
    color: #071135;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 10px 0px 0px 0px !important;
  }
}
.radioButtons {
  position: sticky;
  top: 0;
  z-index: 1000;
  .ant-radio-group {
    border-radius: 12px !important;
    border: 1px solid #e4e8ed !important;
    background: #fff !important;
    padding: 8px !important;
    width: 100%;
  }
  .heading {
      color: #071135 !important;
      text-align: center !important;
      font-family: Arial !important;
      font-size: 24px !important;
      font-style: normal;
      font-weight: 700 !important;
      line-height: normal;
  }
  .ant-radio-button-wrapper {
    border: none !important;
    border-radius: 8px !important;
    padding: 10px 12px !important;
    height: auto !important;
    span {
      color: #071135 !important;
      text-align: center !important;
      font-family: Arial !important;
      font-size: 16px !important;
      font-style: normal;
      font-weight: 700 !important;
      line-height: normal;
    }
  }
  .ant-radio-button-wrapper:not(:last-child) {
    margin-right: 16px;
  }
  .ant-radio-button-wrapper::before {
    content: "" !important;
    width: 0px !important;
  }
  .ant-radio-button-wrapper-checked {
    background: #071135 !important;
    border-radius: 8px !important;
    span {
      color: #fff !important;
    }
  }
}
.radioButtons2{
  position: sticky;
  top: 0;
  z-index: 1000;
  .ant-radio-group {
    border-radius: 12px !important;
    border: 1px solid #e4e8ed !important;
    background: #fff !important;
    width: 100%;
  }
  .heading {
      color: #071135 !important;
      text-align: center !important;
      font-family: Arial !important;
      font-size: 24px !important;
      font-style: normal;
      font-weight: 700 !important;
      line-height: normal;
  }
  .ant-radio-button-wrapper {
    border: none !important;
    border-radius: 8px !important;
    padding: 4px 12px !important;
    height: auto !important;
    span {
      color: #071135 !important;
      text-align: center !important;
      font-family: Arial !important;
      font-size: 16px !important;
      font-style: normal;
      font-weight: 700 !important;
      line-height: normal;
    }
  }
  .ant-radio-button-wrapper:not(:last-child) {
    margin-right: 16px;
  }
  .ant-radio-button-wrapper::before {
    content: "" !important;
    width: 0px !important;
  }
  .ant-radio-button-wrapper-checked {
    background: #071135 !important;
    border-radius: 8px !important;
    span {
      color: #fff !important;
    }
  }
}
.professional_experience_container {
  .professional_summary_box {
    border-radius: 12px;
    border: 1px solid #e4e8ed;
    background: #fff;
    padding: 24px 30px;
    .title {
      color: #071135;
      font-family: Arial;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin: 0px 0px 16px 0px;
    }
    .desc {
      color: #071135;
      font-family: Arial;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      margin: 0px 0px 0px 0px;
      line-height: normal;
    }
  }
}
.qualification_container {
  .qualification_summary_box {
    border-radius: 12px;
    border: 1px solid #e4e8ed;
    background: #fff;
    padding: 24px 30px;
    .mt-30 {
      margin-top: 30px;
    }
  }
  .qualification_detail_box {
    border-radius: 12px;
    border: 1px solid #e4e8ed;
    background: #fff;
    padding: 24px 30px;
    margin-top: 18px;
  }
}
