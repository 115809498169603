.login_main_container {
  display: flex;
  justify-content: center;
  // align-items: center;
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-text-fill-color: rgba(0, 0, 0, 0.88) !important;
    -webkit-box-shadow: 0 0 0 30px #fff inset !important;
  }
  min-height: 100vh;
  .login_container {
    width: 100%;
    background: var(--Top-Bar, #091647);
    .login-form-container {
      padding: 47px;
      .form-outer-div {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 100px;
        .form-inner-div {
          margin-top: 40px;
          width: 500px;
          .forgot-text {
            text-align: center;
          }
        }
      }
    }
    .banner-image .ant-image {
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }
  .innerHeading {
    color: #fff;
    text-align: center;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 0px !important;
  }
  .headingCol {
    display: flex;
    justify-content: center;
  }
  .heading {
    color: #fff;
    text-align: center;
    font-family: Arial;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    // line-height: 40px;
    margin-bottom: 0px !important;
  }
  .desc {
    color: #fff;
    text-align: center;
    font-family: Arial;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    margin-bottom: 41px !important;
    margin-top: 0px !important;
  }
  .submitBtn {
    border-radius: 8px !important;
    background: #f9cf66 !important;
    margin-top: 50px;
    color: #272b39 !important;
    text-align: center;
    font-family: Arial;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    height: 40px;
    padding: 6px 12px !important;
    height: auto !important;
  }
  .ant-input-affix-wrapper {
    border: 1px solid #ced4da !important;
    background: #fff !important;
    padding: 6px 12px !important;
    border-radius: 0 !important;
    height: 38px !important;
  }
  .ant-input-prefix {
    background: #f2f4f8 !important;
    margin: -6px 10px -6px -12px !important;
    /* padding: 10px !important; */
    width: 40px !important;
    display: flex !important;
    justify-content: center !important;
  }
  .ant-form-item {
    margin-bottom: 18px !important;
  }
  .otpContainer {
    gap: 5px !important;
    justify-content: space-between;
  }
  .otpInput {
    text-align: center;
    width: 100% !important;
    height: 60px;
    border-radius: 8px;
    height: 60px;
    font-size: 16px;
    font-weight: 600;
    font-family: "Poppins";
    border: 2px solid #636363;
  }
  .otpInput:focus-visible {
    /* outline: none !important; */
    outline: #636363 !important;
  }
  .resendText {
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    text-align: end;
    width: 100%;
  }
}
.cb_remember_me {
  color: #fff;
  font-family: Arial;
  font-size: 19px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.progressbar_margin {
  margin: -10px 0px 5px 0px;
}
.errorDescBox {
  border-radius: 15px;
  background: #f8f9ff;
  color: #071135;
  text-align: center;

  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 10px 20px;
  span {
    font-weight: 700;
  }
}
.weakText {
  color: #d03c28;

  font-family: Arial;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.strongText {
  color: #31b948;

  font-family: Arial;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.ant-progress-status-normal .ant-progress-bg {
  background: #d03c28 !important;
}
.ant-progress-status-success .ant-progress-bg {
  background: #31b948 !important;
}
.formProgressBar {
  margin-bottom: 0px !important;
  .ant-progress-text {
    display: none !important;
  }
  .ant-progress-bg {
    // background: #d03c28 !important;
    height: 4px !important;
  }

  .ant-progress-outer {
    margin: 0 !important;
    padding: 0 !important;
    max-width: 209px !important;
    height: 4px !important;
    .ant-progress-inner {
      background: #e4e8ed !important;
    }
  }
}
.alertMsg {
  margin-top: 8px;
  span {
    color: #d03c28;
    text-align: center;

    font-family: Arial;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
