body {
  background: #f2f4f8;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
.cursor-pointer {
  cursor: pointer !important;
}
.d-flex {
  display: flex !important;
}
.justify-center {
  justify-content: center !important;
}
.w-100 {
  width: 100% !important;
}
.color-primary {
  color: #272b39 !important;
}
.color-secondary {
  color: #f9cf66 !important;
}
.color-white {
  color: #fff !important;
}
.mb-8 {
  margin-bottom: 8px !important;
}
.mt-16 {
  margin-top: 16px !important;
}
.mb-16 {
  margin-bottom: 16px !important;
}
.mt-20 {
  margin-top: 20px !important;
}
.mb-0 {
  margin-bottom: 0px !important;
}
.mb-20 {
  margin-bottom: 20px !important;
}
.mt-24 {
  margin-top: 24px !important;
}
.mb-24 {
  margin-bottom: 24px !important;
}
.primaryBtn {
  color: #fff !important;
  text-align: center;
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24.043px;
  border-radius: 8px;
  background: #f9cf66;
  padding: 6px 16px !important;
  height: auto !important;
}
.secondaryBtn {
  color: #272b39 !important;
  text-align: center;
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24.043px;
  border-radius: 8px;
  padding: 6px 16px !important;
  height: auto !important;
  border: 1px solid #272b39 !important;
}
.ant-table-cell {
  color: #272b39 !important;
}
th {
  font-weight: 700 !important;
}
.loaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.label-items {
  color: var(--Surface-Primary, #f3f5f9);
  text-align: center;

  font-family: Arial;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.heading1 {
  color: var(--Surface-Primary, #f3f5f9);
  font-family: Arial;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.subHeading1 {
  color: var(--Surface-Primary, #f3f5f9);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.ql-toolbar.ql-snow {
  border-radius: 6px 6px 0px 0px !important;
}
.ql-container.ql-snow {
  border-radius: 0px 0px 6px 6px !important;
}
.ql-editor {
  min-height: 200px !important;
}
.customPagination {
  width: 100%;
  justify-content: end;
  display: flex;
  margin-top: 24px;
}
.custom_pagination {
  .ant-pagination-simple-pager {
    display: none !important;
  }
  .ant-pagination {
    a {
      border-radius: 6px !important;
      height: 32px !important;
      background: #fff !important;
      padding: 0px 0px !important;
      display: flex !important;
      justify-content: center !important;
      align-items: center !important;
      border: 1px solid #e4e8ed !important;
      path {
        stroke: #1b2444 !important;
      }
    }
  }
  .ant-pagination-disabled {
    a {
      border: 1px solid #acbcf5 !important;
      path {
        stroke: #acbcf5 !important;
      }
    }
  }
  padding: 15px 8px;
  .subDesc {
    color: #1638b1;

    /* Body text/B3 */
    font-family: Arial;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;

    line-height: normal;
    span {
      color: #1b2444;
      font-weight: 700;
    }
  }
  .mt-3 {
    margin-top: -2px !important;
  }
  .pageNumbers {
    margin: 0px 0px 0px 8px;
    padding-left: 8px;
    border-left: 1px solid #e7e8f2;
  }
}
::-webkit-scrollbar {
  width: 6px !important; /* width of the entire scrollbar */
  height: 6px !important;
}

::-webkit-scrollbar-track {
  background: transparent !important; /* color of the tracking area */
  border: 1px solid #acbcf5 !important;
  border-radius: 74px !important;
}

::-webkit-scrollbar-thumb {
  background-color: #acbcf5 !important; /* color of the scroll thumb */
  border-radius: 74px !important; /* roundness of the scroll thumb */
}
.collapseContainer {
  .ant-collapse-header {
    padding: 0px !important;
  }
  .ant-collapse-content-box {
    padding: 0px !important;
    margin-top: 20px !important;
  }
  .ant-collapse-borderless {
    background-color: transparent !important;
  }
  .ant-collapse-header-text {
    color: #071135 !important;
    font-family: Arial !important;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 700 !important;
    line-height: normal;
  }
}
.ant-switch.ant-switch-checked {
  background-color: #f8cf65 !important;
}
.ant-switch-checked {
  .ant-switch-inner {
    background-color: #f8cf65 !important;
  }
}

//css for switch

.bulk_button {
  height: 45px;
  background-color: #071135;
  color: #fff !important;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 800;
  & :hover {
    color: #f8cf65 !important;
    border-color: #f8cf65 !important;
    outline: #f8cf65 !important ;
  }
}
.text-capitalize {
  text-transform: capitalize !important;
}
.mt-30 {
  margin-top: 30px;
}
.align_center {
  display: flex !important;
  align-items: center !important;
}
.justify_end {
  display: flex;
  justify-content: flex-end;
}
.h-100 {
  height: 100% !important;
}

.errorPage {
  .title {
    color: #302c48;
    text-align: center;
    font-family: Arial;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 12px;
    margin-bottom: 22px !important;
  }
  .desc {
    margin-top: 0px !important;
    color: #302c48;
    text-align: center;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
.profileDropdown {
  border-radius: 8px !important;
  box-shadow: 1px 2px 23px 0px rgba(0, 0, 0, 0.12) !important;
  background: #fff !important;
  // margin-left: 10px !important;
  min-width: 230px !important;
  max-width: 230px !important;
  &:hover {
    background: #fff !important;
  }
  ul {
    width: 100%;
    background: #fff !important;
    padding: 0 !important;
    border-radius: 8px !important;
    overflow: hidden !important;
    &:hover {
      background: #fff !important;
    }
    li {
      padding: 0 !important;
    }
    .actionDropdown {
      .item {
        display: flex;
        display: flex;
        width: 131px;
        padding: 10px;
        align-items: center;
        gap: 6px;
        // border-radius: 8px 8px 0px 0px;
        border-bottom: 0.5px solid var(--greys-grey-300, #e4e8ed);
        background: var(--greys-primary-white, #fff);
        color: #071135 !important;
        cursor: pointer;
        font-family: Arial;
        font-size: 16px !important;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      .logOutItem {
        color: #d03c28 !important;
        border-bottom: none !important;
      }
      .item:hover {
        background-color: #e3e9fc !important;
        // border-radius: 4px !important;
      }

      .ant-dropdown-menu-item {
        background-color: white !important;
        padding: 0 !important;
      }
    }
  }
  .ant-dropdown-menu-item {
    background-color: white !important;
  }
}
.modal-mount .ant-modal-mask,
.modal-mount .ant-modal-wrap {
  backdrop-filter: blur(10px);
}
.justify_items_end {
  display: flex;
  justify-content: flex-end;
}
.black_color_button {
  border-radius: 8px;
  background: var(--blues-blue-800, #091647);
  box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
  display: flex;
  height: 40px;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: var(--Primary-White, var(--greys-primary-white, #fff));
  text-align: center;
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  &:hover {
    background: var(
      --blues-blue-800,
      #091647
    ) !important; /* Same color on hover */
    color: var(--Primary-White, var(--greys-primary-white, #fff)) !important;
  }
}
.cancel_btn {
  border-radius: 8px;
  border: 1px solid var(--blues-blue-600, #1638b1);
  background: var(--greys-primary-white, #fff);
  display: flex;
  display: flex;
  width: 95px;
  height: 40px;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  span {
    color: var(--blues-blue-600, #1638b1);
    text-align: center;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}
.Live_text {
  color: var(--states-green, #31b948);

  /* Body text/Bold/B3 Bold */
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.archived_text {
  color: var(--states-red, #d03c28);

  /* Body text/Bold/B3 Bold */
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.ant-message-notice-success {
  .ant-message-notice-content {
    border-radius: 8px !important;
    border: 1px solid #31b948 !important;
    background: #f5fbf8 !important;
    // padding: 14px 16px !important;
    .anticon {
      display: none !important;
    }
    span {
      color: #1d8c31 !important;
      font-family: Arial !important;
      // margin-left: 10px !important;
      font-size: 14px !important;
      font-style: normal;
      font-weight: 400 !important;
      line-height: normal;
      display: flex;
      align-items: center;

      &::before {
        padding-right: 6px !important;
        margin-top: 4px !important;
        // margin-right: 12px !important  ;
        content: url("../Assets/campaign/success.svg");
      }
    }
  }
}
.ant-message-notice-error {
  .ant-message-notice-content {
    border-radius: 8px !important;
    border: 1px solid #d03c28 !important;
    background: #fff1f4 !important;
    // padding: 14px 16px !important;
    .anticon {
      display: none !important;
    }
    span {
      color: #d03c28 !important;
      font-family: Arial !important;
      // margin-left: 10px !important;
      font-size: 14px !important;
      font-style: normal;
      font-weight: 400 !important;
      line-height: normal;
      display: flex;
      align-items: center;

      &::before {
        padding-right: 6px !important;
        margin-top: 4px !important;
        // margin-right: 12px !important  ;
        content: url("../Assets/campaign/danger.svg");
      }
    }
  }
}
