.dashboard_main_container {
  .dashboard_one_container {
    margin-top: 40px;
    .heading {
      color: #272b39;
      font-family: Arial;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin: 12px 0px 12px 0px;
    }
  }
}
.dashboardCard {
  //   height: 111px;
  // padding: 20px !important;
  border-radius: 6px;
  // border: 1px solid #e3e3e3;
  margin: 10px;
  background-image: url(../../Assets/bg1.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  .Cardheading {
    font-size: 1.2rem;
    font-weight: 400;
    font-family: Arial;
    margin-bottom: 0px;
    margin: 0 !important;
  }

  .cardContent {
    font-size: 32px;
    font-weight: 700 !important;
    color: #272b39;
  }
}
